import * as actionTypes from "./actionTypes";
import { appLoading, appRedirect, appSuccess, appError, appSuccessMessage } from "./appActions";
import { toastr } from "react-redux-toastr";
import { logoutUser } from "./authActions";
import {
  downloadContractApi,
  createContractWithoutCertificateApi,
  downloadGuaranteesTableApi,
  getPublicKeyHealthMobility,
  listCountriesApi,
  getHomeInsurancePrice,
  getPublicKeyMultimedia,
  getListPublicKeyMultimedia,
  createAccountForHomeInsurance,
  createContractForHouseInsurance, tokenDigitalFirmaHome_insurance, finishDigitalFirmApi,
  createContractForMultimediaInsurance, finishMultimediaDigitalFirmApi, redirectHealthMutualInsurance, finishHealthMobilityDigitalFirmApi,
  createFrenchZoneHealthMobilityApi,
} from "../../api/InsuranceApi";
import fileDownload from "js-file-download";
import LocalStorageService from "../../services/LocalStorageService";
import moment from "moment";
import { waitFor } from "@testing-library/react";
import { delay } from "../../helpers/delay";

export const listCountriesSuccess = (data) => ({
  type: actionTypes.LIST_COUNTRIES_SUCCESS,
  payload: data,
});

export const listCountriesFailed = (error) => ({
  type: actionTypes.LIST_COUNTRIES_FAILED,
  payload: error,
});

export const listPriceSuccess = (data) => ({
  type: actionTypes.LIST_PRICE_SUCCESS,
  payload: data,
});

export const listPriceFailed = (error) => ({
  type: actionTypes.LIST_PRICE_FAILED,
  payload: error,
});

export const downloadContractSuccess = (data) => ({
  type: actionTypes.DOWNLOAD_CONTRACT_FILE_SUCCESS,
  payload: data,
});

export const downloadContractFailed = (error) => ({
  type: actionTypes.DOWNLOAD_CONTRACT_FILE_FAILED,
  payload: error,
});

export const downloadGuaranteesTableSuccess = (data) => ({
  type: actionTypes.DOWNLOAD_GUARANTEES_TABLE_SUCCESS,
  payload: data,
});

export const downloadGuaranteesTableFailed = (error) => ({
  type: actionTypes.DOWNLOAD_GUARANTEES_TABLE_FAILED,
  payload: error,
});

export const detailByCountryAndRageDateSuccess = (data) => ({
  type: actionTypes.DETAIL_BY_COUNTRY_AND_RAGE_DATE_HEALTH_MOBILITY_SUCCESS,
  payload: data,
});

export const detailByCountryAndRageDateFailed = (error) => ({
  type: actionTypes.DETAIL_BY_COUNTRY_AND_RAGE_DATE_HEALTH_MOBILITY_FAILED,
  payload: error,
});

export const detailMultimediaSuccess = (data) => ({
  type: actionTypes.DETAIL_MULTIMEDIA_SUCCESS,
  payload: data,
});

export const cleanDetailMultimediaSuccess = () => ({
  type: actionTypes.DETAIL_MULTIMEDIA_SUCCESS,
  payload: null,
});


export const getHouseInsurancePriceSuccess = (data) => ({
  type: actionTypes.GET_PRICE_SUCCESS,
  payload: data,
});

export const getHouseInsurancePriceFailed = (error) => ({
  type: actionTypes.GET_PRICE_FAILED,
  payload: error,
});

export const createAccountForHouseInsuranceSuccess = (data) => ({
  type: actionTypes.CREATE_ACCOUNT_HOME_INSURANCE_SUCCESS,
  payload: data,
});


export const createAccountForHouseInsuranceFailed = (error) => ({
  type: actionTypes.CREATE_ACCOUNT_HOME_INSURANCE_FAILED,
  payload: error,
});

export const createContractForHouseInsuranceSuccess = (data) => ({
  type: actionTypes.CREATE_CONTRACT_HOME_INSURANCE_SUCCESS,
  payload: data,
});

export const createContractForHouseInsuranceFailed = (error) => ({
  type: actionTypes.CREATE_CONTRACT_HOME_INSURANCE_FAILED,
  payload: error,
});


export const tokenDigitalFirmaHouseInsuranceSuccess = (dataFirma) => ({
  type: actionTypes.TOKEN_DIGITAL_FIRMA_HOME_INSURANCE_SUCCESS,
  payload: dataFirma,
});

export const tokenDigitalFirmaHouseInsuranceFailed = (error) => ({
  type: actionTypes.TOKEN_DIGITAL_FIRMA_HOME_INSURANCE_FAILED,
  payload: error,
});

export const createContractForMultimediaInsuranceSuccess = (data) => ({
  type: actionTypes.CREATE_CONTRACT_MULTIMEDIA_INSURANCE_SUCCESS,
  payload: data,
});

export const createContractForMultimediaInsuranceFailed = (data) => ({
  type: actionTypes.CREATE_CONTRACT_MULTIMEDIA_INSURANCE_FAILED,
  payload: data,
});

export const createContractForHealthInsuranceSuccess = (data) => ({
  type: actionTypes.CREATE_CONTRACT_HEALTH_MOBILITY_INSURANCE_SUCCESS,
  payload: data,
})

export const createContractForHealthInsuranceFailed = (data) => ({
  type: actionTypes.CREATE_CONTRACT_HEALTH_MOBILITY_INSURANCE_FAILED,
  payload: data,
})

export const listCountries = () => (dispatch) => {
  dispatch(appLoading());
  listCountriesApi()
    .then((response) => {
      const { data } = response;
      dispatch(appSuccess());
      dispatch(listCountriesSuccess(data));
    })
    .catch((error) => {
      dispatch(listCountriesFailed(error));
      dispatch(appError());
      if (error && error.response && error.response.data) {
        console.log("Error: ", error.response.data.message);
      } else {
        console.log("Error: ", "error connected to server");
      }
      toastr.error("ERROR", error.message);
      dispatch(logoutUser());
    });
};

export const downloadContract = (payload) => (dispatch) => {
  const { params, history } = payload;
  dispatch(appLoading());
  downloadContractApi(params)
    .then((response) => {
      const { data } = response;
      dispatch(appSuccess());
      dispatch(downloadContractSuccess(data));
      fileDownload(data, "AttestationSantéMobilité.pdf");
      toastr.success("INFO", "Vous venez de recevoir votre attestation sur votre adresse mail et dans votre rubrique mes documents");
    })
    .catch((error) => {
      dispatch(downloadContractFailed(error));
      dispatch(appError());
      if (error && error.response && error.response.data) {
        console.log("Error: ", error.response.data.message);
      } else {
        console.log("Error: ", "error connected to server");
      }
      toastr.error("ERROR", error.message);
    });
};

export const createContractWithoutCertificate = (payload) => (dispatch) => {
  const { history, params } = payload;
  dispatch(appRedirect());
  createContractWithoutCertificateApi(params)
    .then((response) => {
      const { data } = response;
      let url = data.link;

      let service = LocalStorageService.getService();

      let healthMobilityContract = service.getHealthMobilityContract();
      healthMobilityContract = { ...healthMobilityContract, ...data }
      service.setHealthMobilityContract(healthMobilityContract);

      dispatch(appSuccess());
      window.location.href = url;

    })
    .catch((error) => {
      dispatch(appError());
      if (error && error.response && error.response.data) {
        console.log("Error: ", error.response.data.message);
      } else {
        console.log("Error: ", "error connected to server");
      }
      toastr.error("ERROR", error.message);
    });
};



export const createFrenchZoneHealthMobility = (payload) => (dispatch) => {
  const { history, params } = payload;
  dispatch(appRedirect());
  createFrenchZoneHealthMobilityApi(params)
    .then( async (response) => {
      const { data } = response;
      dispatch(appSuccessMessage("Once you have registered, you will find your certificate in your documents area."));
      await delay(3000);
      dispatch(appSuccess());
      history.push("/documents");
      if (!params.sessionStripe) {
        throw new Error("La variable sessionStripe n'est pas définie dans les paramètres.");
      }
    })
    .catch((error) => {
      dispatch(appError());
      if (error && error.response && error.response.data) {
        console.log("Error: ", error.response.data.message);
      } else {
        console.log("Error: ", "error connected to server");
      }
      toastr.error("ERROR", error.message);
    });
};




export const downloadGuaranteesTable = () => (dispatch) => {
  dispatch(appLoading());
  downloadGuaranteesTableApi()
    .then((response) => {
      const { data } = response;
      dispatch(appSuccess());
      dispatch(downloadGuaranteesTableSuccess(data));
      fileDownload(data, "TableauDeGaranties.pdf");
    })
    .catch((error) => {
      dispatch(downloadGuaranteesTableFailed(error));
      dispatch(appError());
      if (error && error.response && error.response.data) {
        console.log("Error: ", error.response.data.message);
      } else {
        console.log("Error: ", "error connected to server");
      }
      toastr.error("ERROR", error.message);
    });
};

export const getPublicKey = (payload) => (dispatch) => {
  const { zone, month, partnerLevelHealthMobilityPrice, history } = payload;
  console.log("Payload", payload)
  dispatch(appLoading());

  getPublicKeyHealthMobility(zone, month, partnerLevelHealthMobilityPrice)
    .then((response) => {
      const { data } = response;
      const dataDetails = data.data;

      dispatch(appSuccess());
      dispatch(detailByCountryAndRageDateSuccess(dataDetails));
    })
    .catch((error) => {
      dispatch(detailByCountryAndRageDateFailed(error));
      dispatch(appError());
      if (error && error.response && error.response.data) {
        toastr.error(
          "ERROR",
          error.response.data.message + ". " + error.message
        );
        console.log(
          "Error: ",
          error.response.data.message + ". " + error.message
        );
      } else {
        toastr.error('ERROR', 'Une erreur est survenue lors de la connexion au serveur.');
        console.log('Error: ', 'error connected to server');
      }
    });
};

export const getPriceHomeInsurance = (payload) => (dispatch) => {
  const { paymentMethod, rooms } = payload;
  dispatch(appLoading());
  getHomeInsurancePrice(paymentMethod, rooms)
    .then((response) => {
      const { data } = response;
      dispatch(appSuccess());
      dispatch(getHouseInsurancePriceSuccess(data));
    })
    .catch((error) => {
      getHouseInsurancePriceFailed(error);
      dispatch(appError());
      if (error && error.response && error.response.data) {
        toastr.error(
          "ERROR",
          error.response.data.message + ". " + error.message
        );
        console.log(
          "Error: ",
          error.response.data.message + ". " + error.message
        );
      } else {
        toastr.error("ERROR", "Une erreur est survenue lors de la connexion au serveur");
        console.log("Error: ", "error connected to server");
      }
    });
};

export const getPriceMultimediaInsurance = (payload) => (dispatch) => {
  const { price, condition, school, history } = payload;
  dispatch(appLoading());

  getPublicKeyMultimedia(price, condition, school)
    .then((response) => {
      const { data } = response;
      console.log("response");
      console.log(response);
      const dataDetails = data.data;
      dispatch(appSuccess());
      dispatch(detailByCountryAndRageDateSuccess(dataDetails));
    })
    .catch((error) => {
      dispatch(detailByCountryAndRageDateFailed(error));
      dispatch(appError());
      if (error && error.response && error.response.data) {
        toastr.error(
          "ERROR",
          error.response.data.message + ". " + error.message
        );
        console.log(
          "Error: ",
          error.response.data.message + ". " + error.message
        );
      } else {
        toastr.error('ERROR', 'Une erreur est survenue lors de la connexion au serveur.');
        console.log('Error: ', 'error connected to server');
      }
    });
};

export const getListPriceMultimediaInsurance = (payload) => (dispatch) => {
  const { type, school, recurring, history } = payload;
  dispatch(appLoading());

  getListPublicKeyMultimedia(type, school, recurring)
    .then((response) => {
      dispatch(appSuccess());
      const { data } = response;
      const dataDetails = data.data;
      dispatch(listPriceSuccess({ data: dataDetails.stripe }));
    })
    .catch((error) => {
      dispatch(listPriceFailed(error));
      if (error && error.response && error.response.data) {
        dispatch(appError());
        toastr.error(
          "ERROR",
          error.response.data.message + ". " + error.message
        );
        console.log(
          "Error: ",
          error.response.data.message + ". " + error.message
        );
      } else {
        toastr.error('ERROR', 'Une erreur est survenue lors de la connexion au serveur.');
        console.log('Error: ', 'error connected to server');
      }
    });
};

export const createAccountApi = (payload) => (dispatch) => {
  const { fields, callback } = payload;
  dispatch(appLoading());
  createAccountForHomeInsurance(fields)
    .then((response) => {
      const { data } = response;
      const { token, refresh_token } = data;
      const service = LocalStorageService.getService();
      service.setToken({ token, refresh_token });
      service.setUser(data.data);
      dispatch(appSuccess());
      dispatch(createAccountForHouseInsuranceSuccess(data));
      callback();
    })
    .catch((error) => {
      //debugger
      createAccountForHouseInsuranceFailed(error);
      dispatch(appError());
      if (error && error.response && error.response.data) {
        toastr.error(
          "ERROR",
          error.response.data.message + ". " + error.message
        );
        console.log(
          "Error: ",
          error.response.data.message + ". " + error.message
        );
      } else {
        toastr.error("ERROR", "Une erreur est survenue lors de la connexion au serveur");
        console.log("Error: ", "error connected to server");
      }
    });
};

export const createHouseInsuranceContractApi = (payload) => (dispatch) => {
  const { fields, callback } = payload;
  const roommates = [];
  dispatch(appLoading());
  fields.roommates.forEach((roommate) => {
    roommates.push({ ...roommate, birthday: moment(roommate.birthday).format('YYYY-MM-DD') })
  })

  createContractForHouseInsurance({ ...fields, roommates: roommates })
    .then((response) => {
      const { data } = response;
      dispatch(appSuccess());

      dispatch(createContractForHouseInsuranceSuccess(data));

      //debugger
      const service = LocalStorageService.getService();
      service.setHouseContract(data);

      callback();

    })
    .catch((error) => {
      createContractForHouseInsuranceFailed(error);
      dispatch(appError());
      if (error && error.response && error.response.data) {
        toastr.error(
          "ERROR",
          error.response.data.message + ". " + error.message
        );
        console.log(
          "Error: ",
          error.response.data.message + ". " + error.message
        );
      } else {
        toastr.error("ERROR", "Une erreur est survenue lors de la connexion au serveur");
        console.log("Error: ", "error connected to server");
      }
    })
};

export const getTokenDigitalFirmaHome_insuranceApi = (payload) => (dispatch) => {
  const { history } = payload;
  const service = LocalStorageService.getService();
  const dataFirma = service.getHouseContract();
  dispatch(appRedirect());

  const params = dataFirma ?
    {
      customerId: dataFirma.customerId,
      contractId: dataFirma.contractId,
      contractorId: dataFirma.contractorId,
      certificateId: dataFirma.certificateId,
      additionalMultimediaContractId: dataFirma.additionalMultimediaContractId,
      bundleId: dataFirma.bundleId,
      sessionStripe: dataFirma.sessionStripe,
    } : undefined;
  if (params) {
    tokenDigitalFirmaHome_insurance(params)
      .then((response) => {
        const { data } = response;
        const dataFirma = data;
        dispatch(tokenDigitalFirmaHouseInsuranceSuccess({ dataFirma }));
        let url = data.link;
        dispatch(appSuccess());
        window.location.href = url;
      })
      .catch((error) => {
        dispatch(appError());
        dispatch(tokenDigitalFirmaHouseInsuranceFailed(error));
        if (error && error.response && error.response.data) {
          toastr.error(
            "ERROR",
            error.response.data.message + ". " + error.message
          );
          console.log(
            "Error: ",
            error.response.data.message + ". " + error.message
          );
        } else {
          toastr.error("ERROR", "Une erreur est survenue lors de la connexion au serveur");
          console.log("Error: ", "error connected to server");
        }
      })
  }
};

export const finishDigitalFirm = (payload) => (dispatch) => {
  const { fields, history } = payload;
  dispatch(appRedirect());

  finishDigitalFirmApi(fields)
    .then((response) => {
      const { data } = response;
      const service = LocalStorageService.getService();
      service.removeHouseContract();
      dispatch(appSuccess());
      history.push('/documents');
    })
    .catch((error) => {
      dispatch(appError());
      if (error && error.response && error.response.data) {
        toastr.error(
          "ERROR",
          error.response.data.message + ". " + error.message
        );
        console.log(
          "Error: ",
          error.response.data.message + ". " + error.message
        );
      } else {
        toastr.error("ERROR", "Une erreur est survenue lors de la connexion au serveur");
        console.log("Error: ", "error connected to server");
      }
    })
};


export const createMultimediaInsuranceContractApi = (payload) => (dispatch) => {
  const { data, history } = payload;
  dispatch(appRedirect());
  const service = LocalStorageService.getService();
  createContractForMultimediaInsurance(data)
    .then((response) => {
      const { data } = response;
      dispatch(createContractForMultimediaInsuranceSuccess(data));
      //debugger
      let multimediaContractService = service.getMultimediaContract();
      multimediaContractService = { ...multimediaContractService, ...data }
      service.setMultimediaContract(multimediaContractService);
      const errorUrl = window.location.origin + "/home/";
      const successUrl = window.location.origin + "/insurance/sign?context=multimedia";
      const url = "https://cloud.sellandsign.com/calinda/sellandsign/#/contract/" + multimediaContractService.contractId + "/sign;c_id=" + multimediaContractService.contractId + ";no_ui=true;refback=" + encodeURIComponent(successUrl) + ";errorback=" + encodeURIComponent(errorUrl) + ";j_token=" + encodeURIComponent(multimediaContractService.token);
      dispatch(appSuccess());
      window.location.href = url;

    })
    .catch((error) => {
      createContractForMultimediaInsuranceFailed(error);
      dispatch(appError());
      if (error && error.response && error.response.data) {
        toastr.error(
          "ERROR",
          error.response.data.message + ". " + error.message
        );
        console.log(
          "Error: ",
          error.response.data.message + ". " + error.message
        );
      } else {
        toastr.error("ERROR", "Une erreur est survenue lors de la connexion au serveur");
        console.log("Error: ", "error connected to server");
      }
    })
};

export const finishMultimediaDigitalFirm = (payload) => (dispatch) => {
  const { fields, history } = payload;
  dispatch(appRedirect());
  finishMultimediaDigitalFirmApi(fields)
    .then((response) => {
      const { data } = response;
      const service = LocalStorageService.getService();
      service.removeMultimediaContract();
      dispatch(appSuccess());
      history.push('/documents');

    })
    .catch((error) => {
      //dispatch(appError());
      if (error && error.response && error.response.data) {
        toastr.error(
          "ERROR",
          error.response.data.message + ". " + error.message
        );
        console.log(
          "Error: ",
          error.response.data.message + ". " + error.message
        );
      } else {
        toastr.error("ERROR", "Une erreur est survenue lors de la connexion au serveur");
        console.log("Error: ", "error connected to server");
      }
    })
};

export const finishHealthMobilityDigitalFirm = (payload) => (dispatch) => {
  const { fields, history } = payload;
  dispatch(appRedirect());
  finishHealthMobilityDigitalFirmApi(fields)
    .then((response) => {
      const { data } = response;
      const service = LocalStorageService.getService();
      service.removeMultimediaContract();
      dispatch(appSuccess());
      history.push('/documents');

    })
    .catch((error) => {
      dispatch(appError());
      if (error && error.response && error.response.data) {
        toastr.error(
          "ERROR",
          error.response.data.message + ". " + error.message
        );
        console.log(
          "Error: ",
          error.response.data.message + ". " + error.message
        );
      } else {
        toastr.error("ERROR", "Une erreur est survenue lors de la connexion au serveur");
        console.log("Error: ", "error connected to server");
      }
    })
};


export const redirectHealthMutualContractApi = (payload) => (dispatch) => {
  const { history } = payload;
  dispatch(appRedirect());
  redirectHealthMutualInsurance()
    .then((response) => {
      const { data } = response;
      const url = data.url;
      dispatch(appSuccess());
      window.location.href = url;
    })
    .catch((error) => {
      dispatch(appError());
      if (error && error.response && error.response.data) {
        toastr.error(
          "ERROR",
          error.response.data.message + ". " + error.message
        );
        console.log(
          "Error: ",
          error.response.data.message + ". " + error.message
        );
      } else {
        toastr.error("ERROR", "Une erreur est survenue lors de la connexion au serveur");
        console.log("Error: ", "error connected to server");
      }
      history.push('/home');
    })
};

